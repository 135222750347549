//
//
//
//
//
//
//
//
//
//
//
//

import { bgimgUrl2 } from '/src/utils/config.js';
import infoForm from '../../components/infoForm'
import {
	areaList
} from '@vant/area-data'
import {
	getInfo,
	getRegister,
	profileData,
	uploadData
} from '../../api'
export default {
	name: "info",
	data() {
		return {
			areaList,
			value: '',
			status: false,
			show: false,
			cascaderValue: '',
			btnFlag: false,
			btnFlag1: false,
			bgimgUrl2:''
		}
	},
	components: {
		infoForm
	},
	created() {
		this.bgimgUrl2 = bgimgUrl2;
		if (localStorage.getItem('token') != null) {
			this.get_Info();
		} else {
			this.btnFlag1 = true;
		}
	},
	methods: {
		get_Info() {
			getInfo({
				token: localStorage.getItem('token')
			}).then(res => {
				console.log(res.data.data.is_build)
				let is_build = res.data.data.is_build
				if (is_build == 0) {
					this.btnFlag = true
					this.btnFlag1 = true
				}
				if (is_build == 1) {
					this.btnFlag = false
				}
			})
		},
		onOpen() {
			this.show = true
		},
		onClose() {
			console.log(this)
			this.status = false
			this.show = false
		},
		handleClick() {
			this.$router.go(-1)
		},
		formClick(e) {
			if (localStorage.getItem('token') == null) {
				getRegister(e).then(res => {
					if (res.data.code == 0) {
						this.$toast.fail(res.data.msg)
					} else {
						if (res.data.data.userinfo?.is_build == 0) {
							this.btnFlag = true
						} else if (res.data.data.userinfo?.is_build == 1) {
							this.btnFlag = false
						}
						localStorage.setItem('token', res.data.data.userinfo.token)
						localStorage.setItem('flag', '0')
						sessionStorage.removeItem('list')
						sessionStorage.removeItem('list1')
						sessionStorage.removeItem('list2')
						this.$toast.success(res.data.msg)
						setTimeout(() => {
							this.$router.go(-1)
						}, 500)
					}
				})
			} else if (localStorage.getItem('token') != null) {
				profileData(e).then(res => {
					if (res.data.code == 0) {
						this.$toast.fail(res.data.msg)
					} else {
						if (res.data.data?.is_build == 0) {
							this.btnFlag = true
						} else if (res.data.data?.is_build == 1) {
							this.btnFlag = false
						}
						localStorage.setItem('token', res.data.data.token)
						localStorage.setItem('flag', '0')
						sessionStorage.removeItem('list')
						sessionStorage.removeItem('list1')
						sessionStorage.removeItem('list2')
						this.$toast.success(res.data.msg)
						setTimeout(() => {
							this.$router.go(-1)
						}, 500)
					}
				})
			}

		},
		handleUpload() {
			if (localStorage.getItem('token') == null) {
				this.$toast.fail('请登录后操作!')
				return
			}
			uploadData().then(res => {
				console.log(res)
				if (res.data.code == 0) {
					this.$toast.fail(res.data.msg)
				} else {
					this.show2 = true
					this.src = 'https://api.qingshaoniandati.kc87.com' + res.data.data
				}
			})
		},
		btnInfo(e) {
			if (e == 0) {
				this.btnFlag = true
				this.btnFlag1 = true
			}
			if (e == 1) {
				this.btnFlag = true
				this.btnFlag1 = false
			}
		}
	}
}
