//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { areaList } from '@vant/area-data';
import { Dialog, Toast } from 'vant';
import {
  getSchoolInfo,
  getInfo,
  uploadData
} from '../api'
export default {
  name: "infoForm",
  data() {
    return {
      areaList,
      value: '请选择所属地区',
      schoolValue: '请选择所属学校',
      levelValue: '请选择所属年级',
      classValue: '请选择所属班级',
      show: false,
      schoolShow: false,
      cascaderValue: '',
      name: '姓&#32;名：',
      formData: {
        username: '', //	姓名
        province: '440303', //	省份
        city: '', //	城市
        area: '', //	地区
        grade_id: '', 	//ID
        teacher: '', //	辅导老师姓名
        teacher_mobile: '', //	辅导老师联系电话
        parent_mobile: '', //	家长联系电话
      },
      columns: [],
      schoolColumns: [],
      levelColumns: [],
      flag: false,
      src: '',
      show2: false,
      is_build: false
    }
  },
  props: {
    btnFlag: {
      type: Boolean,
      default: false
    },
    btnFlag1: {
      type: Boolean,
      default: false
    }
  },
  created() {
    if (localStorage.getItem('token') != null) {
      this.get_Info()
      this.flag = false
    } else {
      this.flag = true
    }
    this.get_SchoolInfo()
  },
  methods: {
    get_Info() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '加载中...',
        overlay: true
      })
      getInfo({
        token: localStorage.getItem('token')
      }).then(res => {
        if (res.data.data.is_build == 0) {
          this.is_build = true
        } else {
          this.is_build = false
        }
        let data = res.data.data
        console.log(data)
        this.formData.username = data.username
        this.value = data.province + data.city + data.area
        this.schoolValue = data.grade.school.name
        this.levelValue = data.grade.name
        this.formData.teacher = data.teacher
        this.formData.teacher_mobile = data.teacher_mobile
        this.formData.parent_mobile = data.parent_mobile
        this.formData.grade_id = data.grade.id
        this.formData.province = data.province
        this.formData.city = data.city
        this.formData.area = data.area
        this.$emit('btnInfo', res.data.data.is_build)
        Toast.clear()
      })
    },
    onOpen(type) {
      if (this.btnFlag1) {
        if (type === 'diqu') {
          this.show = true
        } else if (type === 'school') {
          this.schoolShow = true
        }
      }

    },
    onClose() {
      this.show = false
    },
    confirm(e) {
      console.log(e)
      this.value = ''
      this.formData.province = e[0].name
      this.formData.city = e[1].name
      this.formData.area = e[2].name
      e.forEach(item => {
        this.value += item.name
      })
      this.show = false
    },
    onConfirm(e, value) {
      this.schoolValue = e[1]
      this.levelValue = e[2]
      this.classValue = e[2]
      this.formData.grade_id = this.columns[value[0]].children[value[1]].children[value[2]].id
      this.schoolShow = false
    },
    onCancel() {
      this.schoolShow = false
    },
    get_SchoolInfo() {
      getSchoolInfo().then(res => {
        console.log(res)
        let data = res.data.data
        this.columns = data
      })
    },
    handleClick() {
      const {
        username,
        province,
        city,
        area,
        grade_id,
        teacher,
        teacher_mobile,
        parent_mobile
      } = this.formData
      if (this._.isEmpty(province)) {
        this.$toast.fail('请选择省份')
      } else if (grade_id.length === 0) {
        this.$toast.fail('请选择学校班级')
      } else if (this._.isEmpty(username)) {
        this.$toast.fail('请输入姓名')
      } else if (this._.isEmpty(teacher)) {
        this.$toast.fail('请输入导师姓名')
      } else {
        this.$emit('formClick', this.formData)
      }
    },
    dialogClick() {
      if (this.is_build) {
        Dialog.confirm({
          message: '你有且仅有这一次修改个人信息的机会，是否确定无误？',
          confirmButtonText: '确认',
          cancelButtonText: '修改'
        })
          .then(() => {
            this.handleUpload()
          })
          .catch(() => {
            // on cancel
          });
      } else {
        this.handleUpload()
      }

    },
    handleUpload() {
      if (localStorage.getItem('token') == null) {
        this.$toast.fail('请登录后操作!')
        return
      }
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: '图片加载中...',
        overlay: true
      })
      uploadData().then(res => {
        console.log(res)
        if (res.data.code == 0) {
          this.$toast.fail(res.data.msg)
        } else {
          let url = 'https://api.qingshaoniandati.kc87.com'
          this.show2 = true
          if (res.data.data[0] == 'h') {
            this.src = res.data.data
          } else if (res.data.data[0] == '/') {
            this.src = url + res.data.data
          }
          Toast.clear()
          this.get_Info()
        }
      })
    }
  }
}
